import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import gateway from "../gateway";

function SelectCity() {
  let { provinceId } = useParams();
  const [cities, setCities] = useState(null);
  const [showAsterisk, setShowAsterisk] = useState(false)
  const getCities = useCallback(async () => {
    try {
      var response = await gateway.getCitiesByProvinceId(provinceId);
      response = JSON.parse(response)
      setCities(response);
      const listHasTeam = response.some(r => r.has_team == "Yes")
      console.log(listHasTeam)
      setShowAsterisk(listHasTeam)
    } catch (err) {
      console.log(err);
    }
  }, [provinceId]);

  useEffect(() => {
    getCities();
  }, [getCities]);

  return (
    <div className="container-fluid select-city">
      <img
        className="logo"
        src={require("../assets/images/Logo.png")}
        alt="China Prayer"
      />
      <div className="mainLogo">
        <img src={require("../assets/images/mainlogo.png")} alt="Chumadventist" />
      </div>
      <div className="row">
        {cities && (
          <>
            <div className="col-md-4 province-map">
              <h2>{cities[0]?.province_name}</h2>
              <img
                src={`https://china-prayer-app-images.s3.us-east-2.amazonaws.com/ProvinceRegionMunicipalityMaps/${cities[0]?.province_image == null ? escape('Beijing Municipality_Large.png') : escape(cities[0]?.province_image)}`}
                alt=""
              />
              {
                showAsterisk && <p className="error">*Pray for the team that recently moved here.</p>
              }
              
            </div>
            <div className="col-md-3 cities">
              <h2>I commit to pray that the Gospel will spread in:</h2>
              {cities.map((c) => {
                return (
                  <div key={c.id} className="list-item">
                    <Link to={"/cities/" + c.id}>
                      <p>{`${c.english_name} - ${c.population} ${c.has_team ? "*" : ""}`}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SelectCity;
