import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import gateway from "../gateway";
import { useSnackbar } from 'notistack';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    const data = {
      email: email,
      password: password,
    };
    await gateway
      .authenticateUser(data)
      .then((res) => {
        if(res.msg)
          enqueueSnackbar(`Wrong username or password`, {
            variant: 'error',
        })
        else{
          localStorage.setItem("loggedInUser",JSON.stringify(res))
          setTimeout(() => history.push("/selectProvince"), 500)
          
        } 
        
      })
      .catch((err)=> {
        console.log(err)
      })
  };

  return (
        <div className="container-fluid admin-panel">
          <img
            className="logo"
            src={require("../assets/images/Logo.png")}
            alt="China Prayer"
          />
          {
            <div className="row">
              <div className="col-md-4 offset-md-4">
                <div className="login-form">
                  <div className="row">
                    <div className="col-12 form-group">
                      <label htmlFor="email">Username</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <button onClick={() => handleSubmit()}className="submit-btn">
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          
        </div>
     );
}

export default Login;
