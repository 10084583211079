import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Button,
  LinearProgress
} from "@material-ui/core";
import MaterialTable from 'material-table';
import gateway from "../gateway";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function ManageCities() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [cities, setCities] = useState(null);
  const columns = [
    { 
        title: '#', 
        editable: 'never', 
        render: rowData => (<span>{rowData !== undefined ? rowData.tableData.id + 1 : ''}</span>) },
    { title: 'Download PDF', render: rowData => <span className="href" onClick={()=>downloadPDF(rowData)}>Download PDF</span>},
    { title: 'Province', field: 'province_name', editable: 'never',  },
    { title: 'City Name', field: 'english_name', editable: 'never',  },
    { title: 'Population', field: 'population' },
    { title: 'Meaning', field: 'meaning' },
    { title: 'Fact 1', field: 'fact_1' },
    { title: 'Fact 2', field: 'fact_2' },
    { title: 'Fact 3', field: 'fact_3' },
    { title: 'General Information', field: 'general_information' },
    { title: 'Page 2 Description', field: 'page_2_desc' },
    { title: 'Transportation Hub', field: 'transportation_hub' },
    { title: 'Modern Shopping Mall', field: 'modern_shopping_mall' },
    { title: 'Traditional Market', field: 'traditional_market' },
    { title: 'Hospital', field: 'hospital' },
    { title: 'College University', field: 'college_university' },
    { title: 'Temple', field: 'temple' },
    { title: 'Government Office', field: 'government_office' },
    { title: 'Photo 1', field: 'photo_1' },
    { title: 'Photo 1 Title', field: 'photo_1_title' },
    { title: 'Photo 2', field: 'photo_2' },  
    { title: 'Photo 2 Title', field: 'photo_2_title' },
  ]

  const downloadPDF = async(data) => {
    setOpen(true);
    var pdfFile = await gateway.createPdfAndDownload(data)
    const linkSource = `data:application/pdf;base64,${pdfFile}`;
    const downloadLink = document.createElement("a");
    const fileName = `${data.english_only_name}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setOpen(false)
  }

  const getAllCities = useCallback(async () => {
    try {
      const response = await gateway.getAllCities();
      setCities(response);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const updateCities = async (oldData, newData) => {
      const dataUpdate = [...cities];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      setCities([...dataUpdate]);
      return await gateway.updateCity(newData);
  }

  useEffect(() => {
    if(localStorage.getItem("loggedInUser") == null || JSON.parse(localStorage.getItem("loggedInUser")).id == null){
      history.push("/login")
    }
    getAllCities()
  },[getAllCities, history]);

  
  return (
    <div className="container-fluid admin-dashboard">
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row">
        <div className="col-md-2">
          <img
            className="logo"
            src={require("../assets/images/Logo.png")}
            alt="China Prayer"
          />
        </div>
        <div className="col-md-10">
            <div className="row details">
              <div className="col-md-2 total-users-box">
                <h5 className="total-users">Cities Data</h5>
              </div>
              <div className="col-md-9 passwordCol">
                <Button variant="contained" color="secondary" href="/admin/updatePassword">
                  Update Password
                </Button>
                <Button variant="contained" color="secondary" href="/admin">
                  View Prayer Registrations
                </Button>
              </div>
              <div className="col-md-12">
                <Card>
                    {
                        cities ? 
                        <MaterialTable
                            title="Cities"
                            columns={columns}
                            data={cities}
                            options={{
                                actionsColumnIndex: -1,
                                search: true,
                                exportButton: true,
                                pageSize: 20,
                                sorting: true,
                                headerStyle: {
                                    backgroundColor: '#FFF',
                                    color: 'black'
                                },
                                emptyRowsWhenPaging: false,
                                exportAllData: true
                            }}
                            editable={{
                                onRowUpdate: async (newData, oldData) => await updateCities(oldData, newData),
                            }}
                        />
                        : <div className="loader">
                            <h4>Loading Cities ...</h4>
                            <LinearProgress />
                        </div>
                    }
                </Card>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ManageCities;
