import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from "./Components/Homepage";
import SelectProvince from "./Components/SelectProvince";
import SelectCity from "./Components/SelectCity";
import PrayForm from "./Components/PrayForm";
import AdminDashboard from "./Components/AdminDashboard";
import { SnackbarProvider } from "notistack";
import Login from './Components/Login';
import UpdatePassword from "./Components/UpdatePassword";
import ManageCities from "./Components/ManageCities";

function App() {
  return (
    <Router>
      <React.Fragment>
        <SnackbarProvider>
          <Route name="home" exact path="/" component={HomePage} />
          <Route
            name="selectProvince"
            exact
            path="/selectProvince"
            component={SelectProvince}
          />
          <Route
            name="selectCity"
            exact
            path="/province/:provinceId"
            component={SelectCity}
          />
          <Route
            name="prayForm"
            exact
            path="/cities/:cityId"
            component={PrayForm}
          />
          <Route
            name="admin"
            exact
            path="/admin"
            component={AdminDashboard}
          />
          <Route
            name="admin"
            exact
            path="/admin/updatePassword"
            component={UpdatePassword}
          />
          <Route
            name="admin"
            exact
            path="/admin/manageCities"
            component={ManageCities}
          />
          <Route
            name="login"
            exact
            path="/login"
            component={Login}
          />
        </SnackbarProvider>
      </React.Fragment>
    </Router>
  );
}

export default App;
