import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import gateway from "../gateway";
import { Checkmark } from 'react-checkmark'
import { useHistory } from "react-router-dom";

function PrayForm() {
  let { cityId } = useParams();
  const [city, setCity] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [terms, setTerms] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  const getCity = useCallback(async () => {
    try {
      const response = await gateway.getCityById(cityId);
      setCity(JSON.parse(response));
    } catch (err) {
      console.log(err);
    }
  }, [cityId]);

  useEffect(() => {
    getCity();
  }, [getCity]);

  const addUserAndCreatePdf = async (e) => {
    e.preventDefault();
    if(terms == false){
      setError("Please agree to terms & conditions. Click on the link above to read the terms & conditions.")
    }
    else{
      var userId = (JSON.parse(localStorage.getItem("loggedInUser"))).id
      const user = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        city: city.english_name,
        province: city.province_name,
        userId: userId
      };

      const data = {
        city: city,
        user: user,
      };

      try{
        var res = gateway.addUserPrayerDetails(user)
        setSubmitted(true)  
        setTimeout(function(){
          res.then((r) => {
            gateway.createPdfAndEmail(data, r.reg_id);
            setTimeout(() => history.push("/"), 15000)
          })
        },1000)
        
      } catch(ex){
        console.log(ex)
      }
      
    }
    
    
  }

  return (
    <div className="container-fluid pray-form">
      <img
        className="logo"
        src={require("../assets/images/Logo.png")}
        alt="China Prayer"
      />
      <div className="mainLogo">
        <img src={require("../assets/images/mainlogo.png")} alt="Chumadventist" />
      </div>
      <div className="row mt100">
        {city != null ? (
          <>
            <div className="col-md-3 offset-md-1 caption">
              <h4>
                You've selected <b>{city.english_name}</b>. We've created a special
                prayer map. Your map has important information about this
                unreached city.
              </h4>
            </div>

            <div className="col-md-4 prayer-form">

              {
                submitted ? (
                <>
                  <Checkmark size='xxLarge'/>
                  <span className="confirmation-message">Thank you for committing to pray for {city.english_name}. Your map has been sent.</span>
                </>
              ) : (
                <form onSubmit={addUserAndCreatePdf}>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="firstName"
                        className="form-control"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="lastName"
                        className="form-control"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 form-group">
                      <label htmlFor="email">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 form-group">
                      <input name="termsCheckbox" id="termsCheckbox" type="checkbox" onChange={(e) => setTerms(e.target.value)} /> 
                      <label for="termsCheckbox"><b>I agree to the <a target="_blank" href="https://www.termsfeed.com/live/11a92c24-022a-44a4-9fb5-7f389f3c7fbf">Terms and Conditions</a></b>.</label>
                    </div>
                    {
                      error !== "" &&
                      <div className="col-12 form-group">
                        <span class="error">{error}</span>
                      </div>
                    }
                    <div className="col-6">
                      <button type="submit" className="submit-btn">
                        Send My Map
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default PrayForm;