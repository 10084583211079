var url = "";
class Gateway {
  getAllProvinces = () =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/province")
        .then((res) => res.text())
        .then((res) => resolve(res))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getAllCities = () =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/city")
        .then((res) => res.text())
        .then((res) => {
          var docs = JSON.parse(res);
          resolve(docs)
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  updateCity = (newData) => 
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/city", {
        method: "PUT",
        body: JSON.stringify(newData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(
          resolve()
        )
        .catch((error) => {
          reject(error);
        });
    })

  getProvinceById = (provinceId) =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/province/" + provinceId)
        .then((res) => res.text())
        .then((res) => resolve(res))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getCitiesByProvinceId = (provinceId) =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/city/?provinceId=" + provinceId)
        .then((res) => res.text())
        .then((res) => resolve(res))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getCityById = (cityId) =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/city/" + cityId)
        .then((res) => res.text())
        .then((res) => resolve(res))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getAllRegistrations = () =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/user/prayerRegistrations")
        .then((res) => res.text())
        .then((res) => resolve(res))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  deleteRegistrations = (ids) =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/user/prayerRegistrations/delete", {
        method: "POST",
        body: JSON.stringify(ids),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.text())
        .then((res) => resolve(res))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  addUserPrayerDetails = (user) =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/user/prayerRegistration", {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
        },
      }) 
      .then((res) => res.json())
      .then((res) => resolve(res))
      .catch((error) => {
        console.log(error);
        reject(error);
      });
    });

  createPdfAndDownload = (data) =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/downloadCityPdf", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.text())
        .then((res) => resolve(res))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  createPdfAndEmail = (data, regId) =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/sendEmail?reg_id=" + regId, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => resolve(res))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  updatePassword = (data) =>
  new Promise((resolve, reject) => {
    fetch(url + "/api/v1/admin/updatePassword", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.text())
      .then((res) => resolve(res))
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });

  authenticateAdmin = (data) =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/admin/login", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.text())
        .then((res) => resolve(res))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  authenticateUser = (data) =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/user/login", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          res.json().then((resJson) => resolve(resJson));
        })
        .catch((err) => {
          reject(err);
        });
    });

  registerUser = (data) =>
    new Promise((resolve, reject) => {
      fetch(url + "/api/v1/user/register", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          res.json().then((resJson) => resolve(resJson));
        })
        .catch((err) => {
          reject(err);
        });
    });
}

const gateway = new Gateway();

export default gateway;
