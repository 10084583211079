import React, { useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";


function Homepage() {
  const history = useHistory();

  const initializeApp = useCallback(async () => {
    if (
      localStorage.getItem("loggedInUser") != null &&
      localStorage.getItem("loggedInUser") !== {}
    ) {
    } else {
      history.push("/login");
    }
  }, [history]);

  useEffect(() => {
    initializeApp();
  }, [initializeApp]);

  return (
    <div className="container-fluid homepage">
      <div className="logo">
        <img src={require("../assets/images/Logo.png")} alt="China Prayer" />
      </div>
      <div className="mainLogo">
        <img src={require("../assets/images/mainlogo.png")} alt="Chumadventist" />
      </div>
      <div className="praying-person">
        <img src={require("../assets/images/PrayingMan.png")} alt="" />
      </div>
      <div className="pray-button">
        <Link to="/selectProvince" id="pray-button" className="pray-btn">
          Click to select a city
        </Link>
      </div>
    </div>
  );
}

export default Homepage;
