import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import gateway from "../gateway";
import { useSnackbar } from 'notistack';

function UpdatePassword() {
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    const data = {
      password: password,
    };
    if(password !== cPassword){
      enqueueSnackbar(`Password and Confirm Password fields should match.`, {
        variant: 'error',
      })
    }
    else{
      await gateway
      .updatePassword(data)
      .then((res) => {
        console.log(res)
        enqueueSnackbar(`Admin password has been updated successfully`, {
          variant: 'success',
        })
        setTimeout(() => history.push("/admin"), 500)
      })
      .catch((err)=> {
        console.log(err)
      })
    }
    
  };

  return (
        <div className="container-fluid admin-panel">
          <img
            className="logo"
            src={require("../assets/images/Logo.png")}
            alt="China Prayer"
          />
          {
            <div className="row">
              <div className="col-md-4 offset-md-4">
                <div className="login-form">
                  <div className="row">
                    <div className="col-12 form-group">
                      <label htmlFor="password">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 form-group">
                      <label htmlFor="password">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="cPassword"
                        name="cPassword"
                        value={cPassword}
                        onChange={(e) => setCPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <button onClick={handleSubmit}className="submit-btn">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          
        </div>
     );
}

export default UpdatePassword;
