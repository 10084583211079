import React, { useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import gateway from "../gateway";

function SelectProvince() {
  const [provinces, setProvinces] = useState(null);
  const history = useHistory();

  const getProvinces = useCallback(async () => {
    try {
      const response = await gateway.getAllProvinces();
      setProvinces(JSON.parse(response));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("loggedInUser") == null ||
      JSON.parse(localStorage.getItem("loggedInUser")).id == null
    ) {
      history.push("/login");
    }
    getProvinces();
  }, [getProvinces, history]);

  return (
    <div className="container-fluid select-province">
      <img
        className="logo"
        src={require("../assets/images/Logo.png")}
        alt="China Prayer"
      />
      <div className="mainLogo">
        <img src={require("../assets/images/mainlogo.png")} alt="Chumadventist" />
      </div>
      <div className="row">
        <div className="col-md-3 heading">
          <h2>Select One</h2>
        </div>
        <div className="col-md-3 list1">
          {provinces ? (
            provinces.map((p, i) => {
              return (
                i <= 14 && (
                  <div key={p.id} className="list-item">
                    <Link to={"/province/" + p.id}>
                      <p>{p.name}</p>
                    </Link>
                  </div>
                )
              );
            })
          ) : (
            <></>
          )}
        </div>
        <div className="col-md-3 list2">
          {provinces ? (
            provinces.map((p, i) => {
              return (
                i > 14 && (
                  <div key={p.id} className="list-item">
                    <Link to={"/province/" + p.id}>
                      <p>{p.name}</p>
                    </Link>
                  </div>
                )
              );
            })
          ) : (
            <></>
          )}
        </div>
        <div className="col-md-3" />
      </div>
    </div>
  );
}

export default SelectProvince;
