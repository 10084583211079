import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {
  Box,
  Card,
  Button,
} from "@material-ui/core";
import gateway from "../gateway";
import Swal from 'sweetalert2'

function AdminDashboard() {
  const [users, setRegistrations] = useState(null);
  const history = useHistory();
  const columns = [
    {
     name: "firstName",
     label: "First Name",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
       filter: false,
       sort: true,
      }
     },
    {
     name: "email",
     label: "Email",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
     name: "city",
     label: "Prayer City",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "province",
      label: "Prayer Province",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "dateAdded",
      label: "Date Registered",
      options: {
       filter: false,
       sort: true,
       customBodyRenderLite: (dataIndex) => {
        let val = users[dataIndex].dateAdded.split("T")[0];
        return val;
        }
      },
     },
     {
        name: "emailSuccess",
        label: "Email Delivered",
        options: {
         filter: false,
         sort: true,
         customBodyRenderLite: (dataIndex) => {
          let val = users[dataIndex].emailSuccess ? <span class="success">YES</span> : <span class="error">NO</span>
          return val;
          }
        }
      },
   ];

  const getUsers = useCallback(async () => {
    try {
      const response = await gateway.getAllRegistrations();
      var resp = JSON.parse(response)
      setRegistrations(resp);
    } catch (err) {
      console.log(err);
    }
  }, []);
  
  useEffect(() => {
    if(localStorage.getItem("loggedInUser") == null || JSON.parse(localStorage.getItem("loggedInUser")).id == null){
      history.push("/login")
    }
    getUsers();
  }, [getUsers, history]);

  const options = {
    onRowsDelete:(e)=>{
      const idsToDelete = e.data.map(d => users[d.dataIndex].id);
      Swal.fire({
        title: 'Delete Registrations?',
        text: `Are you sure you want to delete ${e.data.length} rows? You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          gateway.deleteRegistrations(idsToDelete);
          Swal.fire(
            'Deleted!',
            'Selected rows have been deleted.',
            'success'
          )
        }
      })
    },
    selectableRows:'multiple',
  }

  return (
    <div className="container-fluid admin-dashboard">
      <div className="row">
        <div className="col-md-2">
          <img
            className="logo"
            src={require("../assets/images/Logo.png")}
            alt="China Prayer"
          />
        </div>
        <div className="col-md-10">
          {users ? (
            <div className="row details">
              <div className="col-md-2 total-users-box">
                <h5 className="total-users">{users.length} Prayer Registrations</h5>
              </div>
              <div className="col-md-9 passwordCol">
                <Button variant="contained" color="secondary" href="/admin/updatePassword">
                  Update Password
                </Button>
                <Button variant="contained" color="secondary" href="/admin/manageCities">
                  Update Cities
                </Button>
              </div>
              <div className="col-md-12">
                <Card>
                  <Box p={2} minHeight={56} display="flex" alignItems="center">
                    <Box flexGrow={1} />
                    <div>
                      <Button variant="contained" color="secondary" target="_blank" href="/api/v1/admin/dataDump">
                        Download Data
                      </Button>
                    </div>
                  </Box>
                  <MUIDataTable
                    title={"Prayer Registrations"}
                    data={users}
                    columns={columns}
                    options={options}
                  />
                </Card>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
